* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

h1 {
  margin-top: 73px; /* 네비게이션 바 높이만큼 마진 추가 */
}
@media (max-width: 768px) {
  h1 {
    margin-top: 43px; /* 네비게이션 바 높이만큼 마진 추가 */
  }
}

body::after {
  content: "";
  position: fixed; /* stretch a fixed position to the whole screen */
  top: 0;
  height: 100vh; /* fix for mobile browser address bar appearing disappearing */
  left: 0;
  right: 0;
  z-index: -1;
  background: url("./ab.jpg");
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}

.App {
  text-align: center;
  padding: 0 20px;
}

.App.dark-mode {
  background-color: rgba(18, 18, 18, 0.85);
  background-blend-mode: darken;
  color: #ffffff;
}

.light-mode .toggle-button span {
  color: #000000; /* 다크 모드가 아닐 때 진한 색상 */
  font-weight: bold;
}

.dark-mode .toggle-button span {
  color: #c24242; /* 다크 모드일 때 밝은 색상 */
  font-weight: bold;
}
button {
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bwicon {
  width: 50px;
  height: 50px;
  background-image: url("./contrast.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.toggle-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
}
.QWERLIVES {
  border: none;
  background: #f0f0f0;
  cursor: pointer;
  outline: none;
  margin: 10px auto;
  padding: 10px 20px;
  font-size: 14px;
  color: #555;
  border-radius: 6px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
}
.lng-bt {
  border: none;
  background: #f0f0f0;
  cursor: pointer;
  outline: none;
  margin: 10px auto;
  padding: 10px 20px;
  font-size: 14px;
  color: #555;
  border-radius: 6px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
}

.toggle-button img {
  width: 80px;
  height: 80px;
}

.toggle-button span {
  margin-top: 8px;
  font-size: 14px;
  color: #555;
}

.App.dark-mode .toggle-button span {
  color: #bbb;
}

.sort-button {
  border: none;
  background: #f0f0f0;
  cursor: pointer;
  outline: none;
  margin: 10px auto;
  padding: 10px 20px;
  font-size: 14px;
  color: #555;
  border-radius: 6px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
}

.App.dark-mode .sort-button {
  background: #444;
  color: #bbb;
}

.navbar {
  width: 100%;
  background-color: #333;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.navbar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
}

.navbar ul li {
  position: relative;
}

.navbar a,
.navbar .nav-item {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  white-space: nowrap; /* 모든 항목 동일한 넓이 */
}

.navbar a:hover,
.navbar .nav-item:hover {
  background-color: #ddd;
  color: black;
}

.navbar .dropdown li {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: center; /* 텍스트 가운데 정렬 */
  font-size: 14px;
  white-space: nowrap; /* 모든 항목 동일한 넓이 */
}

.navbar .dropdown li a {
  color: black;
  text-decoration: none;
  display: block;
  font-size: 14px;
  padding: 12px 16px;
  text-align: center; /* 텍스트 가운데 정렬 */
  white-space: nowrap; /* 모든 항목 동일한 넓이 */
}

.navbar .dropdown li a:hover {
  background-color: #ddd;
}

.navbar .dropdown li:hover {
  background-color: #ddd;
}

.navbar .nav-item:hover .dropdown {
  display: block;
  width: 100%; /* 부모 요소의 넓이를 기준으로 설정 */
}

.container {
  padding-top: 60px;
}

.timeline {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px 0;
  z-index: 1; /* 추가: 타임라인의 z-index 낮추기 */
}

.timeline::after {
  content: "";
  position: absolute;
  width: 2px;
  background-color: #000;
  top: 0;
  bottom: 250px;
  left: 50%;
  margin-left: -1px;
  z-index: 1;
}

.App.dark-mode .timeline::after {
  background-color: #ffffff;
}

.timeline-item {
  position: relative;
  width: 50%;
  padding: 10px 40px;
  box-sizing: border-box;
}

.timeline-item.left {
  left: 0;
}

.timeline-item.right {
  left: 50%;
}

.timeline-item .circle {
  position: absolute;
  top: 15px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  z-index: 2;
  overflow: hidden;
  background-color: #00bfff;
}

.timeline-item .circle-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.timeline-item.left .circle {
  right: -18px;
}

.timeline-item.right .circle {
  left: -18px;
}

.timeline-item .content {
  padding: 20px;
  background-color: #f9f9f9;
  position: relative;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.App.dark-mode .timeline-item .content {
  background-color: #333;
  color: #ffffff;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}
.App.light-mode .timeline-item .content {
  background-color: #333;
  color: #ffffff;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}
.timeline-item.left .content {
  text-align: right;
}

.timeline-item.right .content {
  text-align: left;
}

.timeline-item .content h2 {
  margin: 0;
  font-size: 20px;
}

.timeline-item .content p {
  margin: 5px 0;
}

.timeline-item .content a {
  color: #00bfff;
  text-decoration: none;
}

.timeline-item .content .thumbnail {
  width: 100%;
  height: auto;
  border-radius: 6px;
  margin-bottom: 10px;
}

.loader {
  height: 20px;
}

.footer {
  margin-top: 20px;
  font-size: 12px;
  color: #666;
}

.App.dark-mode .footer {
  color: #aaa;
}

.title-container {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.scrolling-title {
  display: inline-block;
  padding-left: 100%;
  animation: scroll 10s linear infinite;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@media (max-width: 768px) {
  .timeline::after,
  .timeline-item .circle {
    display: none;
  }

  .timeline-item {
    width: 100%;
    padding: 10px 20px;
    left: 0 !important;
  }

  .timeline-item .content {
    text-align: right;
    margin-top: 20px;
  }

  .navbar ul {
    flex-direction: column;
    justify-content: flex-start;
  }

  .navbar ul li {
    width: 100%;
  }

  .navbar .dropdown {
    position: relative;
    width: 100%;
    box-shadow: none;
  }

  .navbar .dropdown li {
    width: 100%;
  }

  .navbar .nav-item {
    cursor: pointer;
  }

  .navbar .dropdown {
    display: none;
  }

  .navbar .nav-item.active .dropdown {
    display: block;
  }

  .hamburger {
    display: block;
    cursor: pointer;
    height: 40px;
  }

  .hamburger .bar {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 4px 0;
    transition: 0.4s;
  }

  .navbar ul {
    display: none;
  }

  .navbar ul.active {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

@media (max-width: 480px) {
  .toggle-button img {
    width: 60px;
    height: 60px;
  }

  .toggle-button span {
    font-size: 12px;
  }
}

.end-gif {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0;
  z-index: 3;
}

.end-gif img {
  max-width: 100%;
  height: auto;
}

/*snspage*/
.SnsPage {
  text-align: center;
  padding: 20px;
}

.toggle-button {
  margin: 20px auto; /* 중앙 정렬을 위해 margin 수정 */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sns-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle2 {
  width: 80px;
  height: 80px;
  border-radius: 15%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.name {
  margin-top: 10px;
  font-size: 18px;
}

.square-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}

.square {
  width: 80px;
  height: 80px;
  background-color: #00bfff00;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.square img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Fancam 페이지 스타일 */
.fanCam {
  padding: 20px;
  text-align: center;
}

.fanCam.dark-mode {
  background-color: rgba(18, 18, 18, 0.85);
  color: #ffffff;
}

.filter-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  position: relative;
  z-index: 3; /* 높게 설정 */
}

.date-picker {
  padding: 10px;
  margin: 5px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 150px;
  z-index: 3; /* 높게 설정 */
}

.filter-button {
  background-color: #34659a;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  margin: 5px;
  font-size: 16px;
  border-radius: 4px;
  height: 48px;
}

.filter-button:hover {
  background-color: #0056b3;
}

.App.dark-mode .filter-container select,
.App.dark-mode .filter-container button,
.App.dark-mode .filter-container .date-picker {
  background-color: #444;
  color: #bbb;
  border: 1px solid #555;
}

.App.dark-mode .filter-button:hover {
  background-color: #222;
}

/* 모바일에서 달력 위치 조정 */
@media (max-width: 768px) {
  .filter-container {
    flex-direction: column;
  }

  .date-picker,
  .filter-button {
    width: 80%;
    margin: 10px 0;
  }
}

.date-picker-wrapper {
  display: inline-block;
  position: relative;
}

.react-datepicker-wrapper {
  width: auto;
}

.react-datepicker-popper {
  z-index: 9999;
}

.react-datepicker__month-read-view–selected-month,
.react-datepicker__year-read-view–selected-year {
  color: #af2727; /* 날짜 선택 색상 */
  font-size: 16px; /* 폰트 크기 조정 */
}
.react-datepicker__triangle {
  left: 50% !important; /* 중앙으로 이동 */
  transform: translateX(-50%) !important; /* 중앙 정렬 */
}
.react-datepicker__navigation {
  top: 10px; /* 상단에서의 거리 조정 */
  width: 16px; /* 버튼의 너비 */
  height: 16px; /* 버튼의 높이 */
  padding: 0;
  line-height: 1.5;
  text-align: center;
  cursor: pointer;
  position: absolute;
}

.react-datepicker__navigation--previous {
  left: 50%; /* 좌측에서 중앙으로 이동 */
  transform: translateX(-50%); /* 중앙 정렬 */
  transform: translateY(-65%); /* 중앙 정렬 */
}

.react-datepicker__navigation--next {
  right: 50%; /* 우측에서 중앙으로 이동 */
  transform: translateX(50%); /* 중앙 정렬 */
  transform: translateY(-65%); /* 중앙 정렬 */
}

.react-datepicker__navigation-icon {
  position: relative;
  top: 10%; /* 중앙 정렬 */
  transform: translateY(15%); /* 중앙 정렬 */
}
.event-picker {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px 0;
}

.event-item {
  padding: 10px 20px;
  margin: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.event-item.selected {
  background-color: #007bff;
  color: white;
}

.event-item:hover {
  background-color: #ddd;
}

.App.dark-mode .event-item {
  background-color: #444;
  color: #bbb;
  border: 1px solid #555;
}

.App.dark-mode .event-item.selected {
  background-color: #0056b3;
}

.App.dark-mode .event-item:hover {
  background-color: #666;
}
/* 
.lettrt-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

input[type="text"],
textarea {
  border: 1.5px rgb(68, 136, 244) solid;
  width: 500px;
  border-radius: 5px;
  padding-left: 10px;
}

input[type="text"] {
  height: 30px;
}

textarea {
  height: 400px;
  padding-top: 10px;
  resize: none;
}

.header {
  height: 30px;
}

input[type="submit"] {
  width: 100px;
  height: 40px;
  font-size: 15px;
  border: 0;
  outline: 1.5px rgb(68, 136, 244) solid;
  border-radius: 5px;
  padding-left: 0px;
  background-color: rgb(164, 199, 255);
}

input[type="submit"]:active {
  outline: 1.5px rgb(27, 76, 155) solid;
  background-color: rgb(68, 136, 244);
}

@media (max-width: 768px) {
  input[type="text"],
  textarea {
    width: 200%;
    padding-left: 20px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
  }

  input[type="submit"] {
    width: 80%;
  }
} */
.overText {
  position: relative;
}
.textt {
  position: absolute;
  bottom: 0;
  left: -89px;
  background-color: #00000077;
  font-weight: bold;
  font-size: 80%;
  color: #fff;
}
.overyt {
  position: relative;
}
.texttt {
  position: absolute;
  width: 1em;
  left: -96px;
  bottom: 80%;
}
